import React from "react";
import avatar from "../images/avatar.png";
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCog } from "@fortawesome/free-solid-svg-icons";

function UserDropdown() {
  const userDropdownParentRef = document.querySelector(".UserDropdownMenu");
  const [openMenu, setOpenMenu] = React.useState(false);
  document.addEventListener("mousedown", handleClickOutside);
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  function handleClickOutside(event: any) {
    if (
      userDropdownParentRef &&
      !userDropdownParentRef.contains(event.target)
    ) {
      //console.log("You clicked outside of me!");
      setOpenMenu(false);
    }
  }

  const onMenuClick = () => {
    setOpenMenu((openMenu) => !openMenu);
  };

  const onMenuItemClick = (e: any) => {
    //e.preventDefault();
    setOpenMenu((openMenu) => !openMenu);
  };

  return (
    <div className="dropdown user UserDropdownMenu">
      <a
        className="nav-link pt-0 float-right"
        data-toggle="dropdown"
        href="#"
        aria-expanded="false"
      >
        <div className="user-panel d-flex">
          {/* <div className="info"> <span >
            <FontAwesomeIcon icon={faEnvelope} className="pr-2" />
              </span>     
              <span >
              <FontAwesomeIcon icon={faCog} className="pr-2" />
                </span>               
            </div> */}
          <div className="image" id="CurrentUserProfileImageStatus">
            <img
              id="CurrentUserProfileImage"
              src={avatar}
              className="img-circle elevation-4"
              alt="User"
              onClick={onMenuClick}
            />
          </div>
        </div>
      </a>
      {openMenu ? (
        <div
          className="dropdown-menu dropdown-menu-right show mt-dropdown"
          aria-labelledby="dropdownMenuButton"
        >
          <a onClick={handleLogout} className="dropdown-item" href="#">
            Logout
          </a>
        </div>
      ) : null}
    </div>
  );
}
export default UserDropdown;
