import { faImage, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import DateField from "../general/date-field";
import mainService from "../../service/main-service";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import TimePicker from "../TimePicker/TimePicker";
import DeleteModal from "../DeleteModal/DeleteModal";
import toast from "react-hot-toast";

function EditForm(props: any) {
  const showAppointmentExchangeList = [
    { value: "MyDeskOnly", caption: "MyDesk meetings only" },
    { value: "MyDeskAndTeams", caption: "MyDesk and Teams meetings" },
    { value: "All", caption: "All appointments" },
  ];

  const showAnonymizeAfterDays = [
    { value: 0, caption: "Never" },
    { value: 14, caption: "After 14 days" },
    { value: 30, caption: "1 Month" },
    { value: 90, caption: "3 Months" },
    { value: 180, caption: "6 Months" },
    { value: 365, caption: "1 Year" },
  ];

  const regexpattern = new RegExp("^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$");

  const emailRegexpattern = new RegExp("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$");
  const passwordRegexpattern = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");

  const [emailSenderAddress, setEmailsender] = useState(props?.editObject?.EmailSenderAddress);
  const [subDomain, setSubDomain] = useState(props?.editObject?.SubDomain);
  const [password, setPassword] = useState(props?.editObject?.Password);

  const ParamOutOfOfficeTypes: any[] = props?.editObject?.OutOfOfficeTypes;
  const [OutOfOfficeTypes, setOutOfOfficeTypes] = useState(ParamOutOfOfficeTypes);
  //console.log("OutOfOfficeTypes", OutOfOfficeTypes)

  function handelEmailsenderonblur(event: any) {
    if (!emailRegexpattern.test(emailSenderAddress)) {
      Swal.fire({ text: "Please insert valid Email Address" });
      setEmailsender("");
    }
  }

  function handelEmailsenderClick(event: any) {
    setEmailsender(event.target.value);
    setTempDefaultData({
      ...tempDefaultdata,
      EmailSenderAddress: event.target.value,
    });
  }

  function handleSubDomainClick(event: any) {
    setSubDomain(event.target.value);
    setTempDefaultData({ ...tempDefaultdata, SubDomain: event.target.value });
  }

  let checkinAutobookingList = [
    { name: 'Yes', value: true },
    { name: 'Inherit from location', value: false },
  ];

  const [autobookingAlwaysCheckedIn, setAutobookingAlwaysCheckedIn] = useState(props?.editObject?.AutobookingAlwaysCheckedIn)

  const handleAutobookingChange = (event: any) => {
    //console.log("event Target", event.target.value);
    const booleanValue = event.target.value == 'true';
    setAutobookingAlwaysCheckedIn(booleanValue);
    setTempDefaultData({ ...tempDefaultdata, AutobookingAlwaysCheckedIn: booleanValue });
  };

  const ParamDomain: any[] = props?.editObject?.Domains;
  var domainsList = [];
  if (ParamDomain.length > 0) {
    for (let i = 0; i < ParamDomain.length; i++) {
      domainsList.push(ParamDomain[i].Name);
    }
  }
  const initialDomain: any[] = domainsList;

  const [domains1, setDomains1] = useState(initialDomain);

  const [trialEndDate, setTrialEndDate] = useState(new Date(props?.editObject?.TrialEndDate));
  const updateStartDate = (dt: string) => {
    setTrialEndDate(new Date(dt));
    setTempDefaultData({
      ...tempDefaultdata,
      TrialEndDate: new Date(dt).toDateString(),
    });
  };

  const [languageDefaultId, setlanguageDefaultId] = useState(props?.editObject?.DefaultLanguageId);
  const [workTimeVersion, setWorkTimeVersion] = useState(props?.editObject?.WorkTimeVersion);

  let obj: any = {
    TenantId: props?.editObject?.TenantId,
    Id: props?.editObject?.Id,
    Name: props?.editObject?.Name,
    SubDomain: subDomain,
    Active: props?.editObject?.Active,
    IsTrial: props?.editObject?.IsTrial,
    TrialEndDate: trialEndDate,
    Price: props?.editObject?.Price,
    EnableColleagueBooking: props?.editObject?.EnableColleagueBooking,
    EnableManagerBooking: props?.editObject?.EnableManagerBooking,
    EnableMeetingRoom: props?.editObject?.EnableMeetingRoom,
    AllowSpecifySeat: props?.editObject?.AllowSpecifySeat,
    MaxDaysOfBookingAhead: props?.editObject?.MaxDaysOfBookingAhead,
    EmailSenderAddress: emailSenderAddress,
    Domains: domains1,
    MobileMenuShowQR: props?.editObject?.MobileMenuShowQR,
    MobileMenuShowCheckin: props?.editObject?.MobileMenuShowCheckin,
    AnonymizeAfterDays: props?.editObject?.AnonymizeAfterDays,
    WorkHoursStart: props?.editObject?.WorkHoursStart,
    WorkHoursEnd: props?.editObject?.WorkHoursEnd,
    SaveReservationsInExchange: props?.editObject?.SaveReservationsInExchange,
    ShowAppointmentsFromExchange: props?.editObject?.ShowAppointmentsFromExchange,
    AllowAutoSelectSameSeat: props?.editObject?.AllowAutoSelectSameSeat,
    MaximumBookingsAllowed: props?.editObject?.MaximumBookingsAllowed,
    EnableCanteen: props?.editObject?.EnableCanteen,
    EnableDeskBooking: props?.editObject?.EnableDeskBooking,
    EnableParking: props?.editObject?.EnableParking,
    EnableGuestBookings: props?.editObject?.EnableGuestBookings,
    UserName: props?.editObject?.UserName,
    Password: password,
    EnableSensors: props?.editObject?.EnableSensors,
    DisclaimerText: props?.editObject?.DisclaimerText,

    ShowGuestregistrationLink: props?.editObject?.ShowGuestregistrationLink,
    ShowInfoscreenLink: props?.editObject?.ShowInfoscreenLink,
    ShowAnalyticsLink: props?.editObject?.ShowAnalyticsLink,
    ShowPlannerLink: props?.editObject?.ShowPlannerLink,
    OutOfOfficeTypes: OutOfOfficeTypes,

    AutobookingAlwaysCheckedIn: autobookingAlwaysCheckedIn,
    EmailCateringToCostCenter: props?.editObject?.EmailCateringToCostCenter,
    SendReservationNotificationToGuest: props?.editObject?.SendReservationNotificationToGuest,
    GuestRegistrationPreRegisterGuestsRegistered: props?.editObject?.GuestRegistrationPreRegisterGuestsRegistered,
    GuestRegistrationPreRegisterGuestsMeetings: props?.editObject?.GuestRegistrationPreRegisterGuestsMeetings,

    WorkTimeVersion: workTimeVersion,
    RoomAlyzerId: props?.editObject?.RoomAlyzerId,
    DefaultLanguageId: languageDefaultId,
    FeatureEnableWorkTime: props?.editObject?.FeatureEnableWorkTime,
    FeatureEnableGuestRegistration: props?.editObject?.FeatureEnableGuestRegistration,
    FeatureEnablePlanner: props?.editObject?.FeatureEnablePlanner,
    FeatureEnableAnalytics: props?.editObject?.FeatureEnableAnalytics,
    FeatureEnableOtherResources: props?.editObject?.FeatureEnableOtherResources,
    FeatureEnableEInk: props?.editObject?.FeatureEnableEInk,
  }

  const [tempDefaultdata, setTempDefaultData] = useState(obj)

  function onWorkTimeVersionChange(event: any) {
    setWorkTimeVersion(event.target.value);
    setTempDefaultData({
      ...tempDefaultdata,
      WorkTimeVersion: event.target.value,
    });
  }
  const [domain1, setDomain1] = useState("");

  function handleDomainClick(event: any) {
    setDomain1(event.target.value);
  }
  function AddDomains(event: any) {
    if (domain1 === null || domain1 === "") {
      Swal.fire({ text: "Please insert domain name" });
    } else if (!regexpattern.test(domain1)) {
      Swal.fire({ text: "Please insert valid domain name" });
    } else {
      domains1.push(domain1);
      setTempDefaultData({ ...tempDefaultdata, Domains: domains1 });
    }
    setDomain1("");
  }
  function Remove(index: number) {
    var array = [...domains1];
    Swal.fire({
      title: "Are you sure want to delete this domain?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        if (index !== -1) {
          array.splice(index, 1);
          setDomains1(array);
        }
      }
    });
    setTempDefaultData({ ...tempDefaultdata, Domains: array });
  }
  const handleFieldChange = useCallback((event: any) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value
    let fieldName = event.target.name

    setTempDefaultData({ ...tempDefaultdata, [fieldName]: value })
  }, [tempDefaultdata])

  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length: number) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/", { state: { needRefresh: generateString(6) } });
  };

  //////////////////////// OutOfOffice start //////////////////

  //const [OutofofficeLists, setOutofofficeLists] = useState<any[]>([]);
  const [OutofofficeDropDownList, setOutofofficeDropDownList] = useState<any[]>([]);

  function LoadOutOfOfficeData(data: any[]) {
    const mappedOutOfOfficeList = data.map((d: any) => ({
      Name: d,
    }));
    setOutofofficeDropDownList(mappedOutOfOfficeList);
  }
  function onLanguageChange(event: any) {
    setlanguageDefaultId(event.target.value);
    setTempDefaultData({
      ...tempDefaultdata,
      DefaultLanguageId: event.target.value,
    });
  }

  const [languageList, setLanguageList] = useState([]);
  async function getLanguageList() {
    let languageData: [] = await mainService.getLanguageList();
    setLanguageList(languageData);
  }

  useEffect(() => {
    async function getOutOfOffice() {
      let data: any = await mainService.getOutOfOfficeList();
      LoadOutOfOfficeData(data);
    }
    getOutOfOffice();
    getLanguageList();
  }, []);

  function handleOutOfOfficeList(event: any, proIndex: number) {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    //console.log("value", value)
    let fieldName = event.target.name;
    let fieldvalue = value;
    var tempOutOfficeList = [...OutOfOfficeTypes];
    tempOutOfficeList[proIndex][fieldName] = fieldvalue;
    setOutOfOfficeTypes(tempOutOfficeList);
    //setTempDefaultData({ ...tempDefaultdata, "OutOfOfficeTypes": tempOutOfficeList });
    setTempDefaultData({
      ...tempDefaultdata,
      OutOfOfficeTypes: tempOutOfficeList,
    });
  }
  const handleImageClick = (proIndex: number) => {
    let inputFile = document.getElementById(`icon_file_+ ${proIndex}`);
    if (inputFile) inputFile.click();
  };

  const handleFileSelect = (event: any, proIndex: number) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imageURL = event.target?.result as string;
        var tempOutOfficeList = [...OutOfOfficeTypes];
        tempOutOfficeList[proIndex].Icon = imageURL;
        setOutOfOfficeTypes(tempOutOfficeList);
        //setTempDefaultData({ ...tempDefaultdata, "OutOfOfficeTypes": tempOutOfficeList });
        setTempDefaultData({
          ...tempDefaultdata,
          OutOfOfficeTypes: tempOutOfficeList,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  function InsertRow() {
    setOutOfOfficeTypes((OutOfOfficeTypes) => [
      ...OutOfOfficeTypes,
      { Id: 0, Text: "", ExchangeShowAs: "", Icon: "" || null, IsAbsence: false },
    ]);
  }
  function DeleteRow(event: any, index: number) {
    var temp = [...OutOfOfficeTypes];
    if (index !== -1) {
      temp.splice(index, 1);
      setOutOfOfficeTypes(temp);
      //setTempDefaultData({ ...tempDefaultdata, "OutOfOfficeTypes": temp });
      setTempDefaultData({ ...tempDefaultdata, OutOfOfficeTypes: temp });
    }
    if (OutOfOfficeTypes.length == 0) {
      InsertRow();
    }
  }
  let IsCall = false;

  // For default languag
  useEffect(() => {
    //setOutOfOfficeTypes([]);
    if (props?.editObject?.OutOfOfficeTypes.length == 0 && IsCall == false) {
      InsertRow();
      IsCall = true;
      //console.log("Tejhdb22")
    }
  }, []);

  const checkBoxList = useMemo(() => ([
    { title: "Is Trial", keyName: "IsTrial" },
    { title: "", keyName: "" },
    { title: "Active", keyName: "Active" },
    { title: "Allow Specify Seat", keyName: "AllowSpecifySeat" },
    { title: "Enable Manager Booking", keyName: "EnableManagerBooking" },
    { title: "Enable Meeting Room", keyName: "EnableMeetingRoom" },
    { title: "Enable Desk Booking", keyName: "EnableDeskBooking" },
    { title: "Enable Parking", keyName: "EnableParking" },
    { title: "Enable Canteen", keyName: "EnableCanteen" },
    { title: "Enable Sensors", keyName: "EnableSensors" },
    { title: "Enable Guest Bookings", keyName: "EnableGuestBookings" },
    { title: "Enable Guest Registration", keyName: "FeatureEnableGuestRegistration" },
    { title: "Show Guest registration Link", keyName: "ShowGuestregistrationLink" },
    { title: "Show Infoscreen Link", keyName: "ShowInfoscreenLink" },
    { title: "Show Analytics Link", keyName: "ShowAnalyticsLink" },
    { title: "Show Planner Link", keyName: "ShowPlannerLink" },
    { title: "Pre register registered guests", keyName: "GuestRegistrationPreRegisterGuestsRegistered" },
    { title: "Pre register meeting guests", keyName: "GuestRegistrationPreRegisterGuestsMeetings" },
    { title: "Enable WorkTime", keyName: "FeatureEnableWorkTime" },
    { title: "Enable Planner", keyName: "FeatureEnablePlanner" },
    { title: "Enable Analytics", keyName: "FeatureEnableAnalytics" },
    { title: "Enable Other Resources", keyName: "FeatureEnableOtherResources" },
    { title: "Enable EInk", keyName: "FeatureEnableEInk" },
    { title: "Mobile Menu Show QR", keyName: "MobileMenuShowQR" },
    { title: "Mobile Menu Show Checkin", keyName: "MobileMenuShowCheckin" },
    { title: "Add bookings in outlook", keyName: "SaveReservationsInExchange" },
    { title: "Enable Colleague Booking", keyName: "EnableColleagueBooking" },
    { title: "Allow Auto Select Same Seat", keyName: "AllowAutoSelectSameSeat" },
    { title: "Send booking notification to guest", keyName: "SendReservationNotificationToGuest" },
    { title: "Send catering email to cost center", keyName: "EmailCateringToCostCenter" },
  ]), [])

  useEffect(() => {
    console.log('props?.editObject', props?.editObject)
  }, [props?.editObject])

  const CheckBox = useCallback(({ title, name }: { title: string, name?: string }) => {
    return (
      <div className="d-flex w-100 align-items-start" style={{ gap: "0.5rem" }}>
        <input
          type="checkbox" className="chekbox" style={{ marginTop: "0.35rem" }}
          checked={name ? tempDefaultdata[name] ?? false : false} name={name ?? undefined}
          onChange={(event) => handleFieldChange(event)}
          id={name}
        />
        <label htmlFor={name} className="col-form-label p-0 flex-fill">
          {title ?? ""}
        </label>
      </div>
    )
  }, [handleFieldChange, tempDefaultdata])

  // WorkTime Sittings states
  const defaultRegistrationType = useMemo(() => ({
    Id: null,
    Text: "Reg. Type",
    Icon: "",
  }), [])
  const [error, setError] = useState<{ Status: boolean, Data: any[] }>({ Status: false, Data: [] })
  const [repeatDataForAllDays, setRepeatDataForAllDays] = useState<any[]>([])
  const weekDays = useMemo(() => ([
    { Id: 1, Day: "Monday" },
    { Id: 2, Day: "Tuesday" },
    { Id: 3, Day: "Wednesday" },
    { Id: 4, Day: "Thursday" },
    { Id: 5, Day: "Friday" },
    { Id: 6, Day: "Saturday" },
    { Id: 0, Day: "Sunday" },
  ]), [])
  const [allRegistrationTypes, setAllRegistrationTypes] = useState<any[]>([])
  const [allWeekDaysNormTime, setAllWeekDaysNormTime] = useState<any>({
    NormTidAutoRegister: false,
    NormTimeAutoRegistrations: (weekDays ?? [])?.map((day) => ({
      Day: day?.Day,
      NormTime: []
    }))
  })
  const removedBlankCards = useMemo(() => ({
    ...allWeekDaysNormTime,
    NormTimeAutoRegistrations: (allWeekDaysNormTime?.NormTimeAutoRegistrations ?? [])?.map((day: any) => ({
      ...day,
      NormTime: (day?.NormTime ?? [])?.filter((entry: any) => (
        typeof entry?.NormTidStartHour === "number" ||
        typeof entry?.NormTidStartMinute === "number" ||
        typeof entry?.NormTidEndHour === "number" ||
        typeof entry?.NormTidEndMinute === "number" ||
        typeof entry?.NormTidRegistrationTypeId === "number"
      ))
    }))
  }), [allWeekDaysNormTime])

  const setState = (dayName: string, key: string, value: any, index?: number) => {
    setAllWeekDaysNormTime((prev: any) => {
      if (key === "NormTidAutoRegister") {
        return {
          ...prev,
          NormTidAutoRegister: value,
        }
      }

      return {
        ...prev,
        NormTimeAutoRegistrations: prev?.NormTimeAutoRegistrations?.map((d: any, i: number) => {
          if (d?.Day === dayName) {
            return {
              ...d,
              NormTime: d?.NormTime?.map((entry: any, entryIndex: number) => {
                if (entryIndex === index) {
                  return {
                    ...entry,
                    [key]: value,
                  }
                } else {
                  return entry
                }
              })
            }
          } else {
            return d
          }
        })
      }
    })
  }

  const RenderDropDown = useCallback(({ day, dayIndex, entry, entryIndex }: { day: any, dayIndex: number, entry: any, entryIndex: number }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isOpen, setIsOpen] = useState(false)

    return (
      <div
        className={`dropdown work-type${isOpen ? " show" : ""}`}
        style={{ backgroundColor: "white" }}
      >
        <button
          className={`btn btn-secondary dropdown-toggle${!entry?.NormTidRegistrationTypeId ? " text-gray" : ""}`}
          style={{ border: "none", boxShadow: "none", backgroundColor: "white", fontSize: "small" }}
          // data-bs-toggle="dropdown"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {entry?.NormTidRegistrationTypeId ? (
            (allRegistrationTypes ?? [])?.find((type) => type?.Id === entry?.NormTidRegistrationTypeId)?.Text ?? ""
          ) : (
            defaultRegistrationType?.Text ?? ""
          )}
        </button>

        {/*Dropdown Menu */}
        <ul className={`dropdown-menu w-100 p-0${isOpen ? " show" : ""}`}>
          <li
            onClick={() => {
              setIsOpen(false)
              setState(day?.Day, "NormTidRegistrationTypeId", defaultRegistrationType?.Id, entryIndex)
            }}
            className={`d-flex justify-content-between align-items-center dropdown-item${entry?.NormTidRegistrationTypeId === null ? " selected" : ""} mb-0`}
          >
            <span className="item-name flex-fill">{defaultRegistrationType?.Text ?? ""}</span>
            {defaultRegistrationType?.Icon && (
              <img src={defaultRegistrationType?.Icon} className="item-icon" alt="type" />
            )}
          </li>
          {(allRegistrationTypes ?? [])?.filter((type) => !type?.IsAbsence)?.map((type, typeIndex) => (
            <li
              key={typeIndex}
              onClick={() => {
                setIsOpen(false)
                setState(day?.Day, "NormTidRegistrationTypeId", type?.Id, entryIndex)
              }}
              className={`dropdown-item d-flex align-items-center gap-2${entry?.NormTidRegistrationTypeId === type?.Id ? " selected" : ""} mb-0`}
            >
              <span className="item-name flex-fill">{type?.Text ?? "--"}</span>
              {type?.Icon && <img src={type?.Icon} className="item-icon" alt="type" />}
            </li>
          ))}
        </ul>
      </div>
    )
  }, [allRegistrationTypes, defaultRegistrationType])

  const handleRemoveTimeRow = (day: any, entryIndex: number) => {
    setAllWeekDaysNormTime((prev: any) => ({
      ...prev,
      NormTimeAutoRegistrations: (prev?.NormTimeAutoRegistrations ?? [])?.map((d: any) => d?.Day === day ? ({
        ...d,
        NormTime: (d?.NormTime ?? [])?.filter((_: any, i: number) => i !== entryIndex)
      }) : (d))
    }))
    if (error?.Status && (error?.Data ?? [])?.some((d: any) => d?.Day === day && d?.Indexes?.includes(entryIndex))) {
      setError((prev: any) => ({
        ...prev,
        Data: (prev?.Data ?? [])?.map((d: any) => d?.Day === day ? ({
          ...d,
          Indexes: d?.Indexes?.filter((i: number) => i !== entryIndex)
        }) : (d))
      }))
    }
  }

  const repeatForAllDays = (repeatData: any[]) => {
    let p = (repeatData ?? [])?.map((i: any) => {
      const a = { ...i }
      delete a["DayOfWeek"]
      return a
    })

    if (repeatData?.length > 0) {
      let weekDaysNormTime = allWeekDaysNormTime?.NormTimeAutoRegistrations?.map((d: any, i: number) => {
        if (i !== 0 && i !== allWeekDaysNormTime?.NormTimeAutoRegistrations.length - 1 && i !== allWeekDaysNormTime?.NormTimeAutoRegistrations.length - 2) {
          return {
            ...d,
            NormTime: [
              ...(d?.NormTime ?? []),
              ...((p?.map ?? [])((a) => ({
                ...a,
                DayOfWeek: i === allWeekDaysNormTime?.NormTimeAutoRegistrations?.length - 1 ? 0 : i + 1
              })))
            ]
          }
        } else {
          return d
        }
      })

      setAllWeekDaysNormTime({
        NormTidAutoRegister: allWeekDaysNormTime?.NormTidAutoRegister,
        NormTimeAutoRegistrations: weekDaysNormTime,
      })
    } else {
    }
  }

  const handleAddTimeRow = (day: any) => {
    setAllWeekDaysNormTime((prev: any) => ({
      ...prev,
      NormTimeAutoRegistrations: prev?.NormTimeAutoRegistrations?.map((d: any, i: number) => d?.Day === day ? ({
        ...d,
        NormTime: [
          ...d?.NormTime,
          {
            DayOfWeek: (i === prev?.NormTimeAutoRegistrations?.length - 1) ? 0 : i + 1,
            NormTidStartHour: null,
            NormTidStartMinute: null,
            NormTidEndHour: null,
            NormTidEndMinute: null,
            NormTidRegistrationTypeId: null,
            UID: "id-" + Date.now() + "-" + Math.floor(Math.random() * 10000),
          }
        ]
      }) : (
        d
      ))
    }))
  }

  const getInvalidEntry = useCallback(() => {
    if (removedBlankCards?.NormTimeAutoRegistrations && removedBlankCards?.NormTimeAutoRegistrations?.length > 0) {
      const addZeroBefore = (number: number) => `${number < 10 ? "0" : ""}${number}`

      const isInvalid = (entry: any) => {
        const startTime = Number(`${addZeroBefore(entry?.NormTidEndHour)}${addZeroBefore(entry?.NormTidEndMinute)}`)
        const endTime = Number(`${addZeroBefore(entry?.NormTidStartHour)}${addZeroBefore(entry?.NormTidStartMinute)}`)
        return (
          typeof entry?.NormTidStartHour !== "number" ||
          typeof entry?.NormTidStartMinute !== "number" ||
          typeof entry?.NormTidEndHour !== "number" ||
          typeof entry?.NormTidEndMinute !== "number" ||
          typeof entry?.NormTidRegistrationTypeId !== "number" ||
          startTime < endTime
        )
      }

      const invalid = removedBlankCards?.NormTimeAutoRegistrations?.filter((daySetting: any) =>
        daySetting?.NormTime?.some((entry: any) => isInvalid(entry))
      )

      return invalid?.map((daySetting: any) => {
        let Indexes: number[] = []

        daySetting?.NormTime?.forEach((entry: any, entryIndex: number) => {
          if (isInvalid(entry)) {
            Indexes?.push(entryIndex)
          }
        })

        return ({
          Day: daySetting?.Day,
          Indexes: Indexes
        })
      })
    }

    return []
  }, [removedBlankCards?.NormTimeAutoRegistrations])

  // Function to generate a random unique ID
  const generateUniqueId = (data: any) => {
    return (data ?? [])?.map((data: any) => ({
      ...data,
      UID: "id-" + Date.now() + "-" + Math.floor(Math.random() * 10000)
    }))
  }

  function EditTenant() {
    const invalid = getInvalidEntry()

    if (!invalid?.length) {
      let requestPayload = { ...removedBlankCards, NormTimeAutoRegistrations: [] }

      removedBlankCards?.NormTimeAutoRegistrations?.forEach((day: any) => {
        if (day?.NormTime && day?.NormTime?.length > 0) {
          day?.NormTime?.forEach((registration: any) => {
            delete registration?.UID
            requestPayload?.NormTimeAutoRegistrations?.push(registration)
          })
        }
      })
      mainService.updateTenantWorkTimeSettings(requestPayload);

      mainService.editTenants(tempDefaultdata);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Record has been Updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      goBack();
    } else {
      setError({ Status: true, Data: invalid })
      toast.error("Invalid entry")
    }
  }

  // Get WorkTime Data
  useEffect(() => {
    mainService.getWorkTimeData().then((response) => {
      if (response && typeof response === "object" && Object?.keys(response)?.length > 0) {
        setAllWeekDaysNormTime({
          ...response,
          NormTimeAutoRegistrations: weekDays?.map((day) => ({
            Day: day?.Day,
            NormTime: generateUniqueId(response?.NormTimeAutoRegistrations ?? [])?.filter((registration: any) =>
              registration?.DayOfWeek === day?.Id
            )
          }))
        })
      } else {
        setAllWeekDaysNormTime({
          NormTidAutoRegister: false,
          NormTimeAutoRegistrations: weekDays?.map((day) => ({
            Day: day?.Day,
            NormTime: []
          }))
        })
        console.warn("GetWorkTimeData Error:", response)
      }
    })
  }, [weekDays])

  // Get All WorkTime Registration Types
  useEffect(() => {
    mainService.getAllRegistrationTypes().then((response) => {
      if (response && response?.length >= 0) {
        setAllRegistrationTypes(response)
      } else {
        setAllRegistrationTypes([])
        console.warn("GetAllRegistrationTypes Error:", response)
      }
    })
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="col-12 p-0 mb-4 mt-3">
            <h5>General Settings</h5>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">Tenant ID</label>
            <div className="col-sm-12">
              <input
                type="text"
                name="TenantId"
                onChange={(event) => handleFieldChange(event)}
                value={tempDefaultdata.TenantId}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label"> Add Domains</label>
            <div className="col-sm-12">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={domain1}
                  name="domain1"
                  onChange={(event) => handleDomainClick(event)}
                  aria-label="domain's name"
                  aria-describedby="basic-addon2"
                />
                <span
                  className="input-group-text"
                  id="basic-addon2"
                  onClick={(event) => AddDomains(event)}
                >
                  Add
                </span>
              </div>

              {domains1.length > 0 ? (
                <div className="col-sm-12 border rounded mb-4 w-100">
                  <div className="row m-0">
                    {domains1.map((dom, index) => (
                      <div className="col-md-6 p-2 row" key={`domain_${index}`}>
                        <div className="col-md-8">{dom}</div>
                        <div className="col-md-4">
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => {
                              Remove(index);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {emailSenderAddress ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Email Sender Address
            </label>
            <div className="col-sm-12">
              <input
                type="email"
                onBlur={handelEmailsenderonblur}
                name="EmailSenderAddress"
                onChange={(event) => handelEmailsenderClick(event)}
                className="form-control"
                value={emailSenderAddress}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">Default Language</label>
            <div className="col-sm-12">
              <select
                name="LanguageList"
                onChange={(event) => onLanguageChange(event)}
                className="form-control"
                // defaultValue={"All"}
                value={tempDefaultdata.DefaultLanguageId}
              >
                {languageList.map((item: any, index: number) => (
                  <option key={`option${index}`} value={item.Id}>
                    {item.Displayname}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.MaxDaysOfBookingAhead <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Max Days Of Booking Ahead
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="MaxDaysOfBookingAhead"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.MaxDaysOfBookingAhead}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.MaximumBookingsAllowed <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Maximum Bookings Allowed
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="MaximumBookingsAllowed"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.MaximumBookingsAllowed}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.WorkHoursStart <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Work Hours Start
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="WorkHoursStart"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.WorkHoursStart}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.WorkHoursEnd <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Work Hours End
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="WorkHoursEnd"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.WorkHoursEnd}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">DisclaimerText</label>
            <div className="col-sm-12">
              <input
                type="text"
                name="DisclaimerText"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.DisclaimerText ?? ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {!tempDefaultdata.ShowAppointmentsFromExchange ? (
                    <span className="text-danger">* </span>
                  ) : (
                    ""
                  )}
                  Show Appointments From Exchange
                </label>

                <div className="col-sm-12">
                  <select
                    name="ShowAppointmentsFromExchange"
                    onChange={(event) => handleFieldChange(event)}
                    className="form-control"
                    value={tempDefaultdata.ShowAppointmentsFromExchange}
                  >
                    {showAppointmentExchangeList.map(
                      (item: any, index: number) => (
                        <option key={`option_${index}}`} value={item.value}>
                          {item.caption}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {tempDefaultdata.AnonymizeAfterDays <= 0 ? (
                    <span className="text-danger">* </span>
                  ) : (
                    ""
                  )}
                  Anonymize After Days
                </label>
                <div className="col-sm-12">
                  <select
                    name="AnonymizeAfterDays"
                    onChange={(event) => handleFieldChange(event)}
                    className="form-control"
                    value={tempDefaultdata.AnonymizeAfterDays}
                  >
                    {showAnonymizeAfterDays.map((item: any, index: number) => (
                      <option key={`option${index}}`} value={item.value}>
                        {item.caption}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              Check in autobooking automatically
            </label>
            <div className="col-sm-12">
              <select
                className="form-control"
                value={tempDefaultdata.AutobookingAlwaysCheckedIn.toString()}
                onChange={handleAutobookingChange}
              >
                {checkinAutobookingList.map((option) => (
                  <option
                    key={option.value.toString()}
                    value={option.value.toString()}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  Send catering email to cost center
                </label>
                <div className="col-sm-12">
                  <input
                    defaultChecked={tempDefaultdata.EmailCateringToCostCenter}
                    value={tempDefaultdata.EmailCateringToCostCenter}
                    type="checkbox"
                    name="EmailCateringToCostCenter"
                    onChange={(event) => handleFieldChange(event)}
                    className="chekbox"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  Send booking notification to guest
                </label>
                <div className="col-sm-12">
                  <input
                    defaultChecked={
                      tempDefaultdata.SendReservationNotificationToGuest
                    }
                    value={tempDefaultdata.SendReservationNotificationToGuest}
                    type="checkbox"
                    name="SendReservationNotificationToGuest"
                    onChange={(event) => handleFieldChange(event)}
                    className="chekbox"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            {(OutOfOfficeTypes ?? [])?.map((proItem: any, proIndex: number) => (
              <div className="row " key={`options${proIndex}`}>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Choose exch. type
                    </label>

                    <div className="col-sm-12">
                      <select
                        name="ExchangeShowAs"
                        onChange={(event) =>
                          handleOutOfOfficeList(event, proIndex)
                        }
                        className="form-control"
                        value={proItem.ExchangeShowAs}
                      >
                        (<option value="">--Select Exch. Type --</option>)
                        {OutofofficeDropDownList.length > 0
                          ? OutofofficeDropDownList.map(
                            (item: any, index: number) => (
                              <option
                                key={`option${index}`}
                                value={item.Name}
                              >
                                {item.Name}
                              </option>
                            )
                          )
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>
                <div className=" col-sm-3">
                  <div className="form-group">
                    <label className="col-sm-12 col-form-label">
                      MyDesk text
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="Text"
                        onChange={(event) =>
                          handleOutOfOfficeList(event, proIndex)
                        }
                        className="form-control"
                        value={proItem.Text}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="form-group">
                    <label className="col-sm-12 col-form-label">
                      Is Absence
                    </label>
                    <div className="col-sm-2">
                      <input
                        type="checkbox"
                        defaultChecked={proItem.IsAbsence}
                        name="IsAbsence"
                        onChange={(event) =>
                          handleOutOfOfficeList(event, proIndex)
                        }
                        className="form-check"
                        value={proItem.IsAbsence}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="form-group">
                    <label className="col-sm-12 col-form-label pl-0">
                      Icon
                    </label>
                    <div className="align-icon">
                      {proItem?.Icon ? (
                        <img
                          id={`iconImage_${proIndex}`}
                          src={proItem.Icon}
                          onClick={() => handleImageClick(proIndex)}
                          style={{ maxWidth: "100px", height: "40px" }}
                          alt=""
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faImage}
                          onClick={() => handleImageClick(proIndex)}
                          style={{ cursor: "pointer", fontSize: "40px" }}
                        />
                      )}
                      <input
                        type="file"
                        id={`icon_file_+ ${proIndex}`}
                        name="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={(event) =>
                          handleFileSelect(event, proIndex)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-1">
                  <div className="form-group">
                    <label className="col-sm-12 col-form-label pl-0">
                      Delete
                    </label>
                    <div className="align-icon">
                      <button
                        className="btn btn-sm plusminus-btn"
                        onClick={(event) => DeleteRow(event, proIndex)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <button onClick={InsertRow} className="btn btn-sm plusminus-btn mb-3">
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="col-12 p-0 mb-4 mt-3">
            <h5>Tenant Settings</h5>
          </div>
          <div className="container-fluid content-container">
            <div className="row mt-2">
              {/* Sub Domain */}
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    {!subDomain && (
                      <span className="text-danger">*</span>
                    )}
                    SubDomain
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text" className="form-control" name="SubDomain"
                      value={subDomain} placeholder="Sub domain"
                      onChange={(event) => handleSubDomainClick(event)}
                    />
                  </div>
                </div>
              </div>

              {/* Name */}
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    {!tempDefaultdata?.Name && (
                      <span className="text-danger">*</span>
                    )}
                    Name
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text" className="form-control" name="Name"
                      value={tempDefaultdata?.Name} placeholder="Name"
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </div>
                </div>
              </div>

              {/* Price */}
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Price</label>
                  <div className="col-sm-12">
                    <input
                      type="number" className="form-control" name="Price"
                      value={tempDefaultdata.Price ?? ""} placeholder="Price"
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </div>
                </div>
              </div>

              {/* Trial End Date */}
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="form-group row">
                  <DateField
                    date={trialEndDate}
                    labelName="Trial End Date"
                    labelClass="col-sm-12 col-form-label"
                    dateInputclass="form-control w-100"
                    dateContainerClass="col-sm-12"
                    onChangeDate={updateStartDate}
                  />
                </div>
              </div>

              {/* Room Alyzer Id */}
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    Room Alyzer Id
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text" className="form-control" name="RoomAlyzerId"
                      value={tempDefaultdata.RoomAlyzerId ?? ""} placeholder="Room Alyzer Id"
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </div>
                </div>
              </div>

              {/* WorkTime Version */}
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    WorkTime Version
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="form-control" name="WorkTimeVersion"
                      value={tempDefaultdata.WorkTimeVersion ?? ""} title="WorkTime Version"
                      onChange={(event) => onWorkTimeVersionChange(event)}
                    >
                      <option value="None">None</option>
                      <option value="Free">Free</option>
                      <option value="Light">Light</option>
                      <option value="Pro">Pro</option>
                    </select>
                  </div>
                </div>
              </div>

              {(checkBoxList ?? [])?.map((checkBox, checkBoxIndex) =>
                <div key={checkBoxIndex} className="col-md-6 col-sm-12 mb-3">
                  {checkBox?.keyName && (
                    <CheckBox title={checkBox?.title} name={checkBox?.keyName} />
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Uncomment below line to Seprate Tenant and WorkTime Setting */}
          {/* </div>

        <div className="col-md-6 col-sm-12"> */}
          <div className="col-12 p-0 mb-4 mt-3">
            <h5>Worktime Settings</h5>
          </div>

          <div className="">
            <div className="d-flex w-100 align-items-start gap-2 mb-3">
              <input
                type="checkbox" className="chekbox" style={{ marginTop: "0.35rem" }}
                checked={allWeekDaysNormTime?.NormTidAutoRegister ? true : false} name="Check here to auto register your worktime"
                onChange={() => setState("", "NormTidAutoRegister", !allWeekDaysNormTime?.NormTidAutoRegister)}
                id="auto-register"
              />
              <label htmlFor="auto-register" className="col-form-label p-0 flex-fill">
                Check here to auto register your worktime
              </label>
            </div>

            <div className="accordion accordion-work-time mb-4">
              {allWeekDaysNormTime?.NormTimeAutoRegistrations && allWeekDaysNormTime?.NormTimeAutoRegistrations?.length > 0 && (
                allWeekDaysNormTime?.NormTimeAutoRegistrations?.map((day: any, dayIndex: number) => {
                  const invalid: any = (error?.Data ?? [])?.find((d: any) => d?.Day === day?.Day)

                  return (
                    <div key={dayIndex} className="accordion-item">
                      <h2 className="accordion-header m-0">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse-${dayIndex}`}
                        >
                          <span style={{ width: "50px" }}>
                            {moment().day(dayIndex + 1).format("ddd")}
                          </span>
                          {day?.NormTime?.length > 0 && (
                            <span className="badge-outline-warning">
                              {day?.NormTime?.length ?? 0}
                            </span>
                          )}
                        </button>
                      </h2>

                      <div id={`flush-collapse-${dayIndex}`} className="accordion-collapse collapse">
                        <div className="accordion-body">
                          <div className="week-day">
                            {day?.NormTime && day?.NormTime?.length > 0 && (
                              day?.NormTime?.map((entry: any, entryIndex: number) =>
                                <div
                                  key={entryIndex}
                                  className={`worktime-entry d-flex ${error?.Status && invalid?.Day === day?.Day && (invalid?.Indexes ?? [])?.some((i: number) => i === entryIndex) ? " error" : ""}`}
                                >
                                  <div className="col-6 d-flex align-items-center flex-fill">
                                    {dayIndex === 0 && (
                                      <div className="repeat-checkbox p-2 d-flex justify-content-center align-items-center flex-fill">
                                        <input
                                          type="checkbox"
                                          onClick={() => {
                                            setRepeatDataForAllDays((prev: any) => (
                                              prev?.some((d: any) => d?.UID === entry?.UID)
                                                ? prev?.filter((d: any) => d?.UID !== entry?.UID)
                                                : [...prev, entry]
                                            ))
                                          }}
                                          id="repeat-for-all-day"
                                          placeholder="Repeat for all day"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    )}

                                    {/*=============== Start Time ===============*/}
                                    <div className="start-time d-flex justify-content-center align-items-center flex-fill" placeholder="Start">
                                      <TimePicker
                                        minutesInterval={15}
                                        value={typeof entry?.NormTidStartHour === "number" && typeof entry?.NormTidStartMinute === "number" ? (
                                          `${entry?.NormTidStartHour < 10 ? `0${entry?.NormTidStartHour}` : entry?.NormTidStartHour}:${entry?.NormTidStartMinute < 10 ? `0${entry?.NormTidStartMinute}` : entry?.NormTidStartMinute}`
                                        ) : (null)}
                                        onChange={(time: string) => {
                                          setRepeatDataForAllDays((repeatDataForAllDays ?? [])?.map((data: any) => {
                                            if (data?.UID === entry?.UID) {
                                              return {
                                                ...data,
                                                NormTidStartHour: Number(moment(time, "HH:mm").format("HH")),
                                                NormTidStartMinute: Number(moment(time, "HH:mm").format("mm")),
                                              }
                                            } else {
                                              return data
                                            }
                                          }))
                                          setState(day?.Day, "NormTidStartHour", Number(moment(time, "HH:mm").format("HH")), entryIndex)
                                          setState(day?.Day, "NormTidStartMinute", Number(moment(time, "HH:mm").format("mm")), entryIndex)
                                        }}
                                        className="form-control input-sm p-1 fw-normal"
                                      />
                                    </div>

                                    {/*=============== End Time ===============*/}
                                    <div className="end-time d-flex justify-content-center align-items-center flex-fill" placeholder="End">
                                      <TimePicker
                                        minutesInterval={15}
                                        value={typeof entry?.NormTidEndHour === "number" && typeof entry?.NormTidEndMinute === "number" ? (
                                          `${moment(entry?.NormTidEndHour, "H").format("HH")}:${moment(entry?.NormTidEndMinute, "m").format("mm")}`
                                        ) : (null)}
                                        onChange={(time: string) => {
                                          setRepeatDataForAllDays((repeatDataForAllDays ?? [])?.map((data: any) => {
                                            if (data?.UID === entry?.UID) {
                                              return {
                                                ...data,
                                                NormTidEndHour: Number(moment(time, "HH:mm").format("HH")),
                                                NormTidEndMinute: Number(moment(time, "HH:mm").format("mm")),
                                              }
                                            } else {
                                              return data
                                            }
                                          }))
                                          setState(day?.Day, "NormTidEndHour", Number(moment(time, "HH:mm").format("HH")), entryIndex)
                                          setState(day?.Day, "NormTidEndMinute", Number(moment(time, "HH:mm").format("mm")), entryIndex)
                                        }}
                                        className="form-control input-sm p-1 fw-normal"
                                      />
                                    </div>
                                  </div>

                                  {/*=============== Registration Type & Delete ===============*/}
                                  <div className="col-6 d-flex justify-content-between align-items-center">
                                    <RenderDropDown
                                      day={day}
                                      dayIndex={dayIndex}
                                      entry={entry}
                                      entryIndex={entryIndex}
                                    />

                                    <button
                                      title="delete"
                                      onClick={() => {
                                        DeleteModal({
                                          ModalTitle: "Are you sure you want to delete this registration?",
                                          ModalConfirm: () => {
                                            handleRemoveTimeRow(day?.Day, entryIndex)
                                            setRepeatDataForAllDays(repeatDataForAllDays?.filter((data) => data?.UID !== entry?.UID))
                                          }
                                        })
                                      }}
                                      className="btn btn-icon delete-btn"
                                    >
                                      <i className="fa-solid fa-trash-can" style={{ color: "#FF5454", fontSize: "15px" }} />
                                    </button>
                                  </div>
                                </div>
                              )
                            )}

                            {/*=============== Add Row ===============*/}
                            <div className="week-day-footer d-flex justify-content-between align-items-center">
                              {dayIndex === 0 ? (
                                <div className="col-6 d-flex align-items-center align-self-stretch gap-2">
                                  <button
                                    type="button"
                                    onClick={() => repeatForAllDays(repeatDataForAllDays)}
                                    className={`btn-repeat-for-all-days${day?.NormTime.length <= 0 ? "-disable" : ""} align-self-stretch`}
                                    disabled={day?.NormTime.length <= 0}
                                    style={{ border: "none" }}
                                  >
                                    Repeat for All days
                                  </button>
                                </div>
                              ) : (
                                <div className="col-6" />
                              )}

                              <div className="col-6 d-flex justify-content-end align-items-center align-self-stretch">
                                <button
                                  type="button"
                                  onClick={() => handleAddTimeRow(day?.Day)}
                                  className="add-registration d-flex align-items-center align-self-stretch gap-2"
                                  style={{ border: "none" }}
                                >
                                  <i className="fa-solid fa-plus app-theme-color" />
                                  Add registration
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button onClick={() => props?.onCloseClick()} className="btn btn-secondary">
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-blue"
          disabled={(
            !emailSenderAddress ||
            tempDefaultdata.MaxDaysOfBookingAhead <= 0 || //!tempDefaultdata.WorkFromHomeText
            !tempDefaultdata.ShowAppointmentsFromExchange ||
            tempDefaultdata.AnonymizeAfterDays < 0 ||
            tempDefaultdata.WorkHoursStart <= 0 ||
            tempDefaultdata.WorkHoursEnd <= 0 ||
            !subDomain ||
            !tempDefaultdata.Name ||
            //tempDefaultdata.Price <= 0 ||
            tempDefaultdata.MaximumBookingsAllowed <= 0
          )}
          onClick={EditTenant}
        >
          Save
        </button>
      </div>
    </>
  );
}
export default EditForm;
