import React from "react"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./msal/config"
import { Toaster } from "react-hot-toast"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"
import ReactDOM from "react-dom/client"
import store from "./store/store"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-daterangepicker/daterangepicker.css"
import "react-confirm-alert/src/react-confirm-alert.css"
import "./index.css"

import App from "./App"
import Footer from "./shared/footer"
import Header from "./shared/header"
import SignIn from "./msal/signIn"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const msalInstance = new PublicClientApplication(msalConfig)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <Header />
        <Provider store={store}>
          <div className="px-3">
            <App />
          </div>
        </Provider>
        <Footer />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>
      <Toaster position="top-right" reverseOrder={false} gutter={8} />
    </MsalProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
