import {configureStore} from '@reduxjs/toolkit';
import apiSlice from './api-slice'

const store=configureStore(
    {
        reducer:{            
            filters: apiSlice.reducer
        }
    }
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch =typeof store.dispatch
export default store;