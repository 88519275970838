import React, { useEffect, useMemo, useRef, useState } from "react"
import moment from "moment"
import "./TimePicker.css"

export default function TimePicker({ value, minutesInterval, onChange, onBlur, className, disabled, min, max }: {
    value?: null | string,
    minutesInterval?: number,
    onChange?: any,
    onBlur?: any,
    className?: string,
    disabled?: boolean,
    min?: string,
    max?: string,
}) {
    const [selectedTime, setSelectedTime] = useState<null | string>(null)
    const selectedHour = useMemo(() => selectedTime ? Number(moment(selectedTime, "HH:mm").format("HH")) : null, [selectedTime])
    const selectedMinute = useMemo(() => selectedTime ? Number(moment(selectedTime, "HH:mm").format("mm")) : null, [selectedTime])

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const divRef: any = useRef()

    const minHr = useMemo(() => moment(min, "HH:mm").format("") !== "Invalid date" ? Number(moment(min, "HH:mm").format("HH")) : 0, [min])
    const minMin = useMemo(() => moment(min, "HH:mm").format("") !== "Invalid date" ? Number(moment(min, "HH:mm").format("mm")) : 0, [min])
    const maxHr = useMemo(() => max !== "24:00" ? moment(max, "HH:mm").format("") !== "Invalid date" ? Number(moment(max, "HH:mm").format("HH")) : 0 : 23, [max])
    const maxMin = useMemo(() => max !== "24:00" ? moment(max, "HH:mm").format("") !== "Invalid date" ? Number(moment(max, "HH:mm").format("mm")) : 0 : 59, [max])

    const handleChange = (hour: number, minute: number) => {
        if (onChange) {
            if (min && typeof min === "string" && min?.length === 5 && hour === minHr && minute < minMin) {
                onChange(moment(`${hour}:${minMin}`, "HH:mm").format("HH:mm"))
            } else if (max && typeof max === "string" && max?.length === 5 && hour === maxHr && minute > maxMin) {
                onChange(moment(`${hour}:${maxMin}`, "HH:mm").format("HH:mm"))
            } else {
                onChange(moment(`${hour}:${minute}`, "HH:mm").format("HH:mm"))
            }
        }
        setSelectedTime(moment(`${hour}:${minute}`, "HH:mm").format("HH:mm"))
    }

    const isHourDisable = (hour: number) => {
        if (min && typeof min === "string" && min?.length === 5 && hour < minHr) {
            return true
        } else if (max && typeof max === "string" && max?.length === 5 && hour > maxHr) {
            return true
        } else {
            return false
        }
    }

    const isMinuteDisable = (selectedHour: null | number, minute: number) => {
        if (min && typeof min === "string" && min?.length === 5 && selectedHour === minHr && minute < minMin) {
            return true
        } else if (max && typeof max === "string" && max?.length === 5 && selectedHour === maxHr && minute > maxMin) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (value) {
            setSelectedTime(moment(value, "HH:mm").format("HH:mm"));
        } else {
            setSelectedTime(null);
        }
    }, [value]);

    useEffect(() => {
        // Function for click event
        const handleOutsideClick = (event: any) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                onBlur && onBlur(moment(`${selectedHour}:${selectedMinute}`, "HH:mm").format("HH:mm"))
                setIsDropdownOpen(false);
            }
        };

        // Adding click event listener
        document.addEventListener("click", handleOutsideClick)
        return () => document.removeEventListener("click", handleOutsideClick)
    }, [divRef, onBlur, selectedHour, selectedMinute])

    return (
        <div className="dropdown time-picker-dropdown" ref={divRef}>
            <button
                type="button"
                style={{ padding: '13px 16px', width: 90 }}
                onClick={() => setIsDropdownOpen(true)}
                className={`${className ? className : ""}${selectedTime ? "" : " text-gray"}${isDropdownOpen ? " show" : ""}`}
                id="dropdownMenuButton"
                disabled={disabled}
                data-bs-toggle={isDropdownOpen ? "" : "dropdown"}
                aria-haspopup="true"
                aria-expanded="false"
            >
                {selectedTime ? selectedTime : "--:--"}
            </button>

            <div
                className={`dropdown-menu ${isDropdownOpen ? " show" : ""}`}
                aria-labelledby="dropdownMenuButton"
                style={{
                    position: "absolute",
                    inset: "auto auto 0px 0px",
                    margin: "0px",
                    transform: "translate3d(0px, -51.2px, 0px)",
                }}
            >
                <div className="dropdown-menu-header">
                    <span className="header-hours">HH</span>
                    <span className="header-minutes">MM</span>
                </div>

                <div className="dropdown-menu-body">
                    {/* Hours */}
                    <div className="scroll">
                        {[...Array(24)].map((_, hourIndex) => (!isHourDisable(hourIndex) &&
                            <button
                                key={hourIndex}
                                onClick={() => handleChange(hourIndex, selectedMinute ? selectedMinute : 0)}
                                disabled={isHourDisable(hourIndex)}
                                className={selectedHour === hourIndex ? "selected" : ""}
                            >
                                {hourIndex < 10 ? "0" + hourIndex : hourIndex}
                            </button>
                        ))}
                    </div>

                    {/* Minutes */}
                    <div className="scroll">
                        {[...Array(!minutesInterval ? 60 : 60 / minutesInterval)].map((_, minuteIndex) => (!isMinuteDisable(selectedHour, minuteIndex) &&
                            <button
                                key={minuteIndex}
                                onClick={() => handleChange(selectedHour ? selectedHour : 0, !minutesInterval ? minuteIndex : minuteIndex * minutesInterval)}
                                disabled={isMinuteDisable(selectedHour, minuteIndex)}
                                className={!minutesInterval ? (
                                    selectedMinute === minuteIndex ? "selected" : ""
                                ) : (
                                    selectedMinute === (minuteIndex * minutesInterval) ? "selected" : ""
                                )}
                            >
                                {(!minutesInterval ? minuteIndex : minutesInterval * minuteIndex) < 10 ? (
                                    !minutesInterval ? "0" + minuteIndex : "0" + minuteIndex * minutesInterval
                                ) : (
                                    !minutesInterval ? minuteIndex : minuteIndex * minutesInterval
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
