export const apiConstant = {
    getLocationsURL: "Admin/Locations",
    getAllTenant: "Admin/Tenant/All",
    saveTenantURL: "Admin/Tenant",
    deleteTenantURL: "Admin/Tenant",
    editTenantURL: "Admin/Tenant",
    //emailTemplateURL:"Admin/Tenant/",

    // getFloorplansURL: "Admin/Floorplans",
    // getAreasURL:"Admin/Areas",
    // getMeetingRoomsURL: "Meetings/WithShared",
    // getEmployeesListURL: "Meetings/WithShared",
    // getFacilitiesListURL: "Meetings/WithShared",    
    // getMeetingsURL: "Meetings/WithShared",
    getThemeURL: "Admin/Theme",
    getOutOfOfficeList: "Admin/Tenant/OutOfOfficeTypes",
    getSearchADGroupsURL: "Admin/AccessRoles",

    getLanguageListURL: "Translations/Languages",

    workTimeRegistrationTypes: "Worktime/RegistrationTypes",
    adminTenantWorkTime: "Admin/Tenant/WorkTime",
}