import React, { useState } from 'react'
import EditForm from '../components/custom/edit-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

function EditTenant(props: any) {
    const location = useLocation()

    let record = {};
    if(location.state.data) {
      record = location.state.data;
    }

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

  return (
    <>
      <div className="container-fluid content-container mt-5">
        <div className="row title-top border-bottom d-flex align-items-center">
          <div className="col-6">
            <h3 className='text-uppercase'>Edit Tenant</h3>
          </div>
          <div className="col-6">
            <div className="text-right">
              <button onClick={goBack} className="btn btn-secondary">
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-2">&nbsp;Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row title-top">
        <div className="col-12">
          <EditForm onCloseClick={goBack} editObject={record} />
          </div>
        </div>
      </div>
    </>
  )
}

export default EditTenant;