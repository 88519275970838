import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mainService from "../service/main-service";
import TableGrid from "../components/table-grid/table-grid";
import Confirmation from "../components/general/confirmation";
import "./tenant-grid.css";
import Swal from "sweetalert2";
import {
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


/*type MultiSelectionList = {
  id: number;
  name: string;
};*/
type TenantGridList = {
  Id: number,
  TenantId: string,
  Name: string,
  SubDomain: string,
  UserName: string,
  WorkHoursStart: number,
  WorkHoursEnd: number
};
type drpValue = {
  key: number,
  value: string
}
function TenantGrid() {

  const [gridTenantData, setGridTenantData] = useState<TenantGridList[]>([]);
  const [gridTenantDataConst, setGridTenantDataConst] = useState<TenantGridList[]>([]);
  const [tenantData, setTenantData] = useState<[]>([]);
  const [list, setList] = useState({});

  let obj: any = {};
  const [dropDownData, setDropDownData] = useState(obj);
  var DrpValue = "";
  const location = useLocation();
  let needRelod = "";
  if (location.state && location.state.needRefresh) {
    needRelod = location?.state?.needRefresh;
  }

  useEffect(() => {
    async function reloadList() {
      const tenantList: any = await mainService.getTenants();
      loadTenant(tenantList);
    }
    if (needRelod) reloadList();
  }, [needRelod])

  const navigate = useNavigate();
  var indexedArray1: { [key: number]: string } = {};
  //const List: any = {}

  const loadTenant = (tenantList: any) => {
    setTenantData(tenantList);
    const mappedTenant = tenantList;
    setGridTenantData(mappedTenant);
    setGridTenantDataConst(mappedTenant);
    /*debugger;
    for(let i = 0; i < tenantList.length; i++) {   
      if(DrpValue.indexOf(`${tenantList[i].Id}:${tenantList[i].Name},`) == -1) {
        DrpValue +=  `${tenantList[i].Id}:${tenantList[i].Name},`;
      }
    }*/
    for (let i = 0; i < tenantList.length; i++) {
      let id = tenantList[i].Id;
      indexedArray1[id] = tenantList[i].Name
    }
    setList(indexedArray1);
  }
  useEffect(() => {
    async function fetchData() {
      const tenantList: any = await mainService.getTenants();
      loadTenant(tenantList);
    }
    fetchData();
  }, []);

  const onAddNew = () => {
    if (tenantData) {
      navigate("/add-tenant", {
        state: { data: tenantData }


      })
    }

  }

  const editRecord = (tId: string) => {
    let editObj = {};
    editObj = tenantData.filter((x: any) => x.Id === tId)[0];
    navigate("/edit-tenant", {
      state: { data: editObj }
    })
  }
  const deleteRecord = (tId: number) => {
    Swal.fire({
      text: 'Are you sure want to delete this Record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProcess(tId);
      }
    })
  }
  const editTemplate = (tId: string) => {
    navigate("/email-template", {
      state: { Id: tId }
    })
  }
  async function deleteProcess(deletingId: number) {

    /*******************DELETE PROCESS********************/
    mainService.deleteTenants(deletingId);


    /********************REFRESH LIST********************/
    const tenantList: any = await mainService.getTenants();
    loadTenant(tenantList);
    setGridTenantData(tenantList)
    setGridTenantDataConst(tenantList)

    /****************REMOVE DELETED ITEM*****************/
    let index = tenantList.findIndex((x: any) => x.Id === deletingId);
    if (index > -1) {
      tenantList.splice(index, 1);
    }

    /*****************RESET DELETING ID******************/
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Record has been deleted successfully',
      showConfirmButton: false,
      timer: 1500
    })
  }

  const [query, setQuery] = useState("");
  function handleChange(event: any) {
    setQuery(event.target.value)
    let filterdData: any[] = [];
    const data: any[] = gridTenantDataConst
    filterdData = data;

    if (event.target.value && event.target.value.length > 0) {
      filterdData = [];
      filterdData = data.filter((x: any) => {
        return x?.Name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
          x?.SubDomain?.toLowerCase().includes(event.target.value.toLowerCase())
      })
    }
    else {
      filterdData = data;
    }
    setGridTenantData(filterdData);
  }

  return (
    <>
      <div className="container-fluid content-container mt-5">
        <div className="row title-top border-bottom d-flex align-items-center">
          <div className="col-md-4 col-sm-6">
            <h3 className='text-uppercase'>Tenant List</h3>
          </div>
          <div className="col-md-4 col-sm-6">
            <input type="search" className="form-control" placeholder="Search" value={query} onChange={handleChange} />
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="text-right">
              {/* <Link to="/add-tenant" className="btn btn-primary"> Add New</Link> */}
              <button className="btn btn-primary" onClick={onAddNew}> <FontAwesomeIcon

                icon={faPlus}
              /> Add New</button>
            </div>
          </div>
        </div>

        <div className="row title-top">
          <div className="col-12">
            <TableGrid
              data={gridTenantData}
              rowsPerPage={100000}
              onEditClick={editRecord}
              onDeleteClick={deleteRecord}
              onEmailClick={editTemplate}
            />
          </div>
        </div>
      </div>
      {/* {showConfirm && (
          <div className={`modal fade ${disClass}`} id="confirmationModel" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document" style={{ maxWidth: "400" }}>
                <Confirmation title={"Delete Confirmation"} onClose={closeConfirm} onDelete={deleteProcess}/>
              </div>
          </div>
      )} */}

    </>
  );
}

export default TenantGrid;
