import Api from './http-common';
import { Gapi } from './http-common';
import { apiConstant } from '../constants/api-constant';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getLocations() {
        var response = await Api().get(apiConstant.getLocationsURL);
        return response.data;
    }
    ,
    async getTenants() {
        var response = await Api().get(apiConstant.getAllTenant);
        return response.data;
    }
    ,

    async saveTenants(data: any) {
        var response = await Api().post(apiConstant.saveTenantURL, data);
        return response.data;
    },

    async deleteTenants(id: any) {
        var response = await Api().delete(apiConstant.deleteTenantURL + "/" + id);
        return response.data;
    },

    async editTenants(data: any) {
        var response = await Api().put(apiConstant.editTenantURL + "/" + data.Id, data);
        return response.data;
    },

    async getEmailTemplates(tId: number, lId: number) {
        var response = await Api().get(`Admin/Tenant/${tId}/Locations/${lId}/MailTemplates`);
        return response.data;
    }
    ,
    async getTheme() {
        var response = await Api().get(apiConstant.getThemeURL);
        return response.data;
    },
    async getSearchADGroup(searchtext: string) {
        var response = await Api().get(apiConstant.getSearchADGroupsURL + "?searchText=" + searchtext);
        console.log(response);
        return response.data;
    },
    async getTenantId(DomainName: string) {
        var response = await Gapi().get(`https://graph.microsoft.com/beta/tenantRelationships/findTenantInformationByDomainName(domainName='${DomainName}')`);
        return response;
    },
    async getOutOfOfficeList() {
        var response = await Api().get(apiConstant.getOutOfOfficeList);
        return response.data;
    },
    async getLanguageList() {
        var response = await Api().get(apiConstant.getLanguageListURL);
        return response.data;
    },
    async getAllRegistrationTypes() {
        var response = await Api().get(apiConstant.workTimeRegistrationTypes);
        return response.data;
    },
    async getWorkTimeData() {
        var response = await Api().get(apiConstant.adminTenantWorkTime);
        return response.data;
    },
    async updateTenantWorkTimeSettings(data: any) {
        var response = await Api().put(apiConstant.adminTenantWorkTime, data);
        return response.data;
    },
}