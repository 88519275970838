import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TenantGrid from "./Pages/tenant-grid";
import EditTenant from "./Pages/edit-tenant";
import AddTenant from "./Pages/add-tenant";
import EmailTemplate from "./Pages/email-template";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    Component: TenantGrid,
  },
  {
    path: "/add-tenant",
    Component: AddTenant,
  },
  {
    path: "/edit-tenant",
    Component: EditTenant,
  },
  // {
  //   path: "/email-template",
  //   Component: EmailTemplate,
  // },
]);

export default AppRouter;
