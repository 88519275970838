import React from "react"
import { confirmAlert } from "react-confirm-alert"
import "./DeleteModal.scss"

export default function DeleteModal({ ModalTitle, ModalConfirm }: { ModalTitle?: string, ModalConfirm?: any }) {
    return (
        confirmAlert({
            customUI: ({ onClose }: { onClose: any }) =>
                <div className="react-confirm-alert-body web-confirm-alert">
                    <div className="w-100 d-flex justify-content-center mb-2"><i className="bi bi-trash" /></div>
                    <h2 className="text-center mb-3">
                        {ModalTitle ? ModalTitle : "Are you sure you want to delete this record?"}
                    </h2>
                    <div className="d-flex justify-content-center w-100">
                        <div className="d-flex">
                            <button onClick={() => onClose()} className="btn btn-secondary me-3">Cancel</button>
                            <button
                                onClick={() => {
                                    ModalConfirm && ModalConfirm()
                                    onClose()
                                }}
                                className="btn btn-primary"
                            >
                                Yes, Delete It
                            </button>
                        </div>
                    </div>
                </div>
        })
    )
}
