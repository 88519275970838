import { useState, useEffect } from "react"
import { PublicClientApplication } from "@azure/msal-browser"
import { tokenScope, graphScope } from "./msal/config"
import { RouterProvider } from "react-router-dom"
import { msalConfig } from "./msal/config"
import mainService from "./service/main-service"
import AppRouter from "./AppRouter"
import "./App.css"

export default function App() {
  const [theme, setTheme] = useState({ primaryColor: "", secondaryColor: "" })

  const msalInstance = new PublicClientApplication(msalConfig)
  const accounts = msalInstance.getAllAccounts()

  useEffect(() => {
    (async () => {
      const themeResponse: any = await mainService.getTheme()

      if (themeResponse && typeof themeResponse === "object" && Object.keys(themeResponse)?.length > 0) {
        setTheme({
          primaryColor: `#${themeResponse?.AppThemeColor}`,
          secondaryColor: `#${themeResponse?.TeamsMeetingColor}`,
        })
      }
    })()
  }, [])

  async function getAccessToken() {
    if (accounts.length > 0) {
      const request = {
        scopes: [tokenScope],
        account: accounts[0],
      }
      const accessToken = await msalInstance.acquireTokenSilent(request).then((response) => {
        return response.accessToken
      }).catch((error) => {
        console.log(error)
        return null
      })

      if (accessToken != null) {
        //console.log("accessToken", accessToken)
        localStorage.setItem("token", accessToken)
      }

      //Graph token
      const graphToken = await msalInstance.acquireTokenSilent({
        scopes: [graphScope],
        account: accounts[0],
      }).then((response) => {
        return response.accessToken
      }).catch((error) => {
        console.log(error)
        return null
      })
      if (graphToken != null) {
        //console.log("accessToken", accessToken)
        localStorage.setItem("graphtoken", graphToken)
      }
      return accessToken
    }
    return null
  }
  getAccessToken()

  useEffect(() => {
    // update CSS variables
    document.documentElement.style.setProperty("--primary-color",theme.primaryColor)
    document.documentElement.style.setProperty("--secondary-color",theme.secondaryColor)
  }, [theme])

  return (
    <RouterProvider router={AppRouter} />
  )
}