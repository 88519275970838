import React, { useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "./config"
import Alert from "react-bootstrap/Alert"

const SignIn = () => {
  const { instance } = useMsal()

  useEffect(() => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.warn("LoginRedirect Error:", error)
    })
  }, [instance])

  return (
    <div>
      <Alert variant="secondary">
        Please wait we redirecting you to login.....
      </Alert>
    </div>
  )
}

export default SignIn